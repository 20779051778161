<template>
  <div class="tags_warp">
    <div class="navBar">
      <div @click="$router.go(-1)" class="back">
        <svg-icon class="leftBtn" iconClass="back"></svg-icon>
      </div>
      <div class="rightBtn" @click="openShare">
        <svg-icon icon-class="share"></svg-icon>
      </div>
    </div>
    <div class="topic_box">
      <div class="topic_header">
        <div class="topic_pic">
          <ImgDecypt :src="info.cover" :key="info.cover" />
        </div>
        <div class="topic_info">
          <h2>#{{ info.city || '未知' }}</h2>
          <div class="topic_frequency">
            <div class="play_num">{{ info.visit | watchCount }}人来过</div>
          </div>
        </div>
      </div>
      <van-tabs
        v-model="activeName"
        class="my_tab"
        @change="changeTab"
        background="rgb(246,246,246)"
        title-active-color="rgb(255, 103, 143)"
        title-inactive-color="rgb(51, 51, 51)"
        animated
        :swipeable="true"
      >
        <van-tab :title="item.name" v-for="(item, index) in tabs" :key="index" :name="item.path">
          <keep-alive>
            <router-view v-if="activeName == item.path" class="tab_view" />
          </keep-alive>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import ImgDecypt from '@/components/ImgDecypt';
import { Tab, Tabs, Toast } from 'vant';
import { queryLocationInfo } from '@/api/video';
import { getSessionItem, removeSession } from '@/utils/longStorage';

export default {
  name: 'citys',
  inject: ['reload'],
  components: {
    ImgDecypt,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
  },
  data() {
    return {
      activeName: '',
      info: {},
      tabs: [
        {
          path: '/citys/video',
          name: '视频',
        },
        {
          path: '/citys/graphics',
          name: '图文',
        },
      ],
      oldId: this.$route.query.id,
      id: '',
    };
  },
  watch: {
    $route(to, from) {
      let fromPath = this.$route.query.fromPath;
      let id = this.$route.query.id;
      if ((fromPath == '/citys/video' || fromPath == '/citys/graphics') && this.oldId != id) {
        this.oldId = this.$route.query.id;
        this.reload();
      }
    },
  },
  created() {
    this.activeName = this.$route.path;
    this.id = this.$route.query.id;
    if (getSessionItem('cityId')) {
      this.id = getSessionItem('cityId');
    }
    this.getInfo();
  },
  methods: {
    //获取城市信息
    async getInfo() {
      let req = {
        id: this.id,
      };
      let res = await this.$Api(queryLocationInfo, req);
      if (res.code === 200) {
        this.info = res.data;
        return;
      }
      Toast(res.tip || '获取城市信息失败');
    },
    changeTab(name) {
      this.$router.replace({ path: name, query: { id: this.$route.query.id, city: this.$route.query.city } });
    },
    openShare() {
      this.$store.commit('user/UPDATE_SHARE', {
        show: true,
        info: {
          title: '网黄Up的分享平台，随时随地发现性鲜事',
          publisher: { name: this.info.name },
          cover: this.info.cover,
          isTag: true,
        },
      });
    },
  },
  beforeDestroy() {
    removeSession('cityId');
  },
};
</script>
<style scoped lang="scss">
.tags_warp {
  height: 100%;
  .navBar {
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    background-color: #fff;

    .back {
      width: 40px;
      display: flex;
      align-items: center;

      .leftBtn {
        width: 24px;
        height: 24px;
        font-size: 24px;
      }
    }

    .title {
      flex: 1;
      font-size: 18px;
      text-align: center;
      color: #000;
    }

    .rightBtn {
      width: 40px;
      font-size: 24px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }

  .topic_box {
    height: calc(100% - 44px);
    padding: 10px 12px;
    .topic_header {
      display: flex;
      justify-content: space-between;
      padding: 10px;
      background-color: #fff;
      border-radius: 4px;
      margin-bottom: 12px;
      .topic_pic {
        width: 58px;
        height: 58px;
        border-radius: 6px;
        overflow: hidden;
        margin-right: 7px;
      }
      .topic_info {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        h2 {
          font-size: 16px;
        }
        .topic_frequency {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .play_num {
            color: rgb(153, 153, 153);
            font-size: 14px;
          }
        }
      }
    }
    .my_tab,
    /deep/ .van-tabs__content,
    /deep/ .van-tab__pane {
      height: calc(100% - 50px);
    }
    .my_tab {
      .tab_view {
        box-sizing: border-box;
        height: 100%;
      }
      /deep/ .van-tabs__wrap {
        padding: 0px 80px;
      }
      /deep/ .van-tab {
        font-size: 16px;
        padding: 0 20px;
      }
      /deep/ .van-tabs__line {
        bottom: 18px;
        width: 20px;
        height: 4px;
        border-radius: 2px;
        background: rgb(255, 103, 143);
      }
    }
  }
}
</style>
